.main-customers {
    color: var(--color-fuente-claro);
    background: rgb(0, 0, 0);
    background: linear-gradient(45deg, #FF5016 -75% ,  var(--color-representativo) 100%);
    padding: 40px 0 40px 0 ;
    max-width: 100%;
    margin: auto;
}

.main-customers H1 {
    font-size: 60px;
}


.chunk-customers {
    max-width: 900px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 30px;
}

.empresas {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 30px;
}

.logo-empresa {
    width: 150px;
    height: 150px;
    border-radius: 10px;
    background-color: #1818221c;
    align-content: center;
}

.contenedor-logo-img{
    padding-top: 10px;
}
    

.contenedor-logo-img img {
    margin: auto;
    width: 100px;
    height: 100px;
    border-radius: 10px;
}

.nombre-empresa {
    padding-bottom: 10px;
}


.nombre-empresa h3{
    font-size: 14px;
    opacity: 0.8;
    font-weight: 100;
}


@media (max-width: 768px) {

    .empresas {
        gap: 20px;
    }

    .logo-empresa .contenedor-logo-img {
        padding: 0;
    }

    .logo-empresa .contenedor-logo-img img {
        margin: 10x;
        width: 55px;
        height: 55px;
    }

    .logo-empresa {
        width: 75px;
        height: 100px;
        border-radius: 10px;
        transition: all 0.5s;
    }

    .nombre-empresa {
        padding-bottom: 5px;
    }
    
    
    .nombre-empresa h3{
        font-size: 10px;
        opacity: 0.8;
        font-weight: 0;
    }
}