body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --color-fondo: #fff;
  --color-fondo-oscuro: #1d1e29;
  --color-fondo-claro: #fff;

  --color-fuente: #000;
  --color-fuente-oscuro: #000;
  --color-fuente-claro: #fff;

  --color-icono: brightness(0) invert(0);
  --color-icono-claro: brightness(0) invert(1);
  --color-icono-oscuro: brightness(0) invert(0);
  
  --color-representativo: #9e0b0b;
}
