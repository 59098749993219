.main-start {
    position: relative;
    background-color: #4d4d4d;
    margin: auto;
    align-content: center;
}

.main-start::before {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(to right, #9E0B0B 0%, #000000 65%, #000000 100%);
    opacity: .6
}

.content-start {
    color: var(--color-fuente-claro);
    max-width: 1500px;
    margin: 50px auto 50px auto;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-around;
    align-items: center;
}

.iconos-start {
    display: flex;
    flex-direction: row;
    background-color: #ffffffa8;
    padding: 10px;
    gap: 20px;
    justify-content: space-around;
    border-radius: 10px;

}

.iconos-start img {
    width: 45px;
}

.inf-start {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    width: 30%;
    text-align: left;
    box-shadow: 5px 5px 15px #00000054,
        -1px -5px 15px #00000048;
    border-radius: 30px;
}

.inf-start div h1{
    font-size: 40px;
    margin-top: 15px;
}

.inf-start div p {
    font-size: 15px;
    color: #e09f9f;
    width: 90%;
}


.img-start {
    width: 55%;
    max-height: 700px;
}

.img-start img {
    width: 100%;
    border-radius: 20px !important;
    box-shadow: 5px 5px 15px #00000054,
        -1px -5px 15px #00000048;
}

.inf-start button{
    width: 150px;
    height: 40px;
    margin-top: 20px;
    font-family: Tahoma, Geneva, sans-serif;
    font-size: 17px;
    font-weight: bolder;
    background-color: #f3f7fe;
    border: none;
    border-radius: 5px;
    transition: .2s;
}

.inf-start button a {
    color: var(--color-representativo);
    text-decoration: none;
}

.inf-start button:hover {
    background-color: var(--color-representativo);
    color: #fff;
}

.inf-start button:hover a {
    color: #fff;
    text-underline-offset: 4px;
}

.start-video {
    max-width: 400px;
    margin: auto;
}

.start-video video {
    width: 100%;
}


/*-----------Slider----------*/

.main-start .slick-dots button {
    transform: translateY(-70px);
}

.main-start .slick-dots button::before {
    font-family: 'slick';
    font-size: 150px;
    content: '-';
    text-align: center;
    opacity: .5;
    color: #ffffff;
}

.main-start .slick-dots li {
    margin: 0 40px 0 40px;
}

/*------responsividad-------*/

@media(max-width: 1153px) {

    .inf-start h1{
        font-size: 30px;
        margin-top: 10px;
    }
}

@media(max-width: 1000px) {

.inf-start div h1 {
    font-size: 25px;
}

.inf-start div p {
    font-size: 14px;
}

.iconos-start img {
    width: 35px;
}

.inf-start button {
    width: 150px;
    height: 30px;
}

.inf-start {
    padding: 20px;
}
}

@media(max-width: 850px) {

    .inf-start div h1 {
        font-size: 20px;
    }

    .inf-start div p {
        font-size: 12px;
    }

    .iconos-start img {
        width: 30px;
    }

    .inf-start button {
        width: 120px;
        height: 28px;
    }

    .inf-start {
        padding: 20px;
    }

    .inf-start button a {
        font-size: 14px;
    }
}

@media(max-width: 660px) {

    .iconos-start img {
        width: 25px;
    }

    .content-start {
        gap: 30px;
        flex-direction: column-reverse !important;
    }

    .inf-start {
        width: 80%;
        border-radius: 10px;
    }

    .img-start {
        width: 88%;
        max-height: 700px;
    }

    .img-start img {
        width: 100%;
        border-radius: 10px !important;
        box-shadow: 5px 5px 15px #00000054,
            -1px -5px 15px #00000048;
    }
}

@media(max-width: 450px) {

    .main-start .slick-dots li {
        margin: 0 20px 0 20px;
        transform: translateX(-15px);
    }
}

