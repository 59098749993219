.main-form-email {
    max-width: 900px;
    background-color: var(--color-fondo);
    display: flex;
    align-self: center;
    padding: 20px 20px 20px 20px ;
}

.text-form-Email{
    margin-bottom: 20px;
}


.text-form-Email * {
    width: 100%;
    padding: 5px 0;
    margin: 0;
    text-align: left;
    color: var(--color-fuente);
    opacity: 0.7;
}

.container-form-contact form {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 85%;
    margin: auto;
    font-family: Inter, sans-serif;
}

.contenedor-input-contacto {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    text-align: left;
    gap: 20px;
}

.contenedor-input-contacto div {
    width: 100%;
    padding-bottom: 10px;
}

.medios-contactos{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
}

.medios-contactos .medicorreo-{
    width: 70%;
}

.medios-contactos .medio-telefono{
    width: 70%;
}

.medios-contactos .medio-telefono input{
    width: 75%;
}

.contenedor-input-contacto div input {
    font-size: 15px;
    width: 90%;
    height: 45px;
    padding: 0 20px 0 20px;
    border-radius: 100px;
    border: 1px solid var(--color-fuente);
    margin-top: 10px;
    color: var(--color-fuente);
    outline: none;
    background-color: var(--color-fondo);
    opacity: 0.7;
}

.contenedor-input-contacto div label {
    font-size: 13px;
    padding-left: 20px;
    color: var(--color-fuente);
    opacity: 0.7;
}




.contenedor-input-contacto div textarea {
    margin-top: 10px;
    height: 140px;
    width: 90%;
    padding: 10px 20px 0 20px;
    border: 1px solid var(--color-fuente);
    border-radius: 18px;
    background-color: var(--color-fondo);
    color: var(--color-fuente);
    opacity: 0.7;
    outline: none;
    resize: none;
    font-size: 15px;
}



.contenedor-input-contacto button:active {
    background-color: var(--color-fondo-claro);
    color: var(--color-representativo);

}

.errorForm {
    color: red;
    font-size: 11px;
    padding: 0 0 0 20px;
    margin: 0;
}


/*--------boton de enviar------*/

.btn-shine {
    background-color: var(--color-representativo);
    width: 125px;
    height: 50px;
    color: var(--color-fuente-claro);
    border-radius: 10px;
    cursor: pointer;
    position: relative;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    z-index: 0;
    transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

/*--------notification--------*/

.main-form-email .notificacion {
    display: grid;
    place-content: center;
    color: #ffffff;
    font-size: 20px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
    transition: opacity 0.5s ease, visibility 0.5s ease;
    opacity: 0;
    visibility: hidden;
  }
  
  .main-form-email .visible {
    opacity: 1;
    visibility: visible;
    
  }
  
  .main-form-email .novisible {
    opacity: 0;
    visibility: hidden;
  }

  .main-form-email .notificacion div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border-radius: 20px;
    align-items: center;
    gap: 10px;
  }

  .main-form-email .success{
    background-color: #659b5eda;
  }
  
  .main-form-email .error{
    background-color: #ff4b4bd2;
  }

  .main-form-email .notificacion div img{
    width:40px;
    height: 40px;
  }

  .main-form-email .notificacion div p{
    margin: 0;
    padding: 0;
  }


@keyframes rotate624 {
    0% {
        transform: rotate(0deg) translate3d(0, 0, 0);
    }

    25% {
        transform: rotate(3deg) translate3d(0, 0, 0);
    }

    50% {
        transform: rotate(-3deg) translate3d(0, 0, 0);
    }

    75% {
        transform: rotate(1deg) translate3d(0, 0, 0);
    }

    100% {
        transform: rotate(0deg) translate3d(0, 0, 0);
    }
}

@keyframes storm1261 {
    0% {
        transform: translate3d(0, 0, 0) translateZ(0);
    }

    25% {
        transform: translate3d(4px, 0, 0) translateZ(0);
    }

    50% {
        transform: translate3d(-3px, 0, 0) translateZ(0);
    }

    75% {
        transform: translate3d(2px, 0, 0) translateZ(0);
    }

    100% {
        transform: translate3d(0, 0, 0) translateZ(0);
    }
}

.btn-shine {
    border: none;
    overflow: hidden;
    position: relative;
}

.btn-shine span {
    z-index: 20;
}

.btn-shine:after {
    background: #ffffff;
    content: "";
    height: 155px;
    left: -75px;
    opacity: 0.4;
    position: absolute;
    top: -50px;
    transform: rotate(35deg);
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
    width: 50px;
    z-index: -10;
}

.btn-shine:hover:after {
    left: 120%;
    transition: all 550ms cubic-bezier(0.19, 1, 0.22, 1);
}

@media(max-width: 950px) {
    .container-form-contact {
        width: 95%;
    }

    .main-form-email {
        flex-direction: column;
    }

    .main-form-email .notificacion {
        font-size: 17px;
      }

    .main-form-email .notificacion div img{
        width:35px;
        height: 35px;
      }
}



@media(max-width:750px) {
    
    .container-form-contact form {
        flex-direction: column;

    }

    .contenedor-input-contacto {
        gap: 5px;
    }
    
    .contenedor-input-contacto div {
        padding-bottom: 5px;

    }

    .medios-contactos{
        display: flex;
        flex-direction: column;

        gap: 5px;
    }

    .contenedor-input-contacto div label {
        font-size: 15px;
    }

    .contenedor-input-contacto div input {
        border-radius: 10px;
        height: 32px;
        margin-top: 3px;
    }

    .contenedor-input-contacto div textarea {
        border-radius: 10px;
        margin-top: 3px;
        height: 100px;

    }
    
    .medios-contactos .medio-telefono{
        width: 100%;
    }

    .medios-contactos .medio-telefono input{
        width: 90%;
    }
}