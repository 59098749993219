/*-------------contenedor principal--------*/
.main-footer {
	background-color: #17171f;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	box-shadow: 0px 0px 20px -7px #000000;
	color: #fff;
}

.main-footer hr {
	opacity: 0.1;
}

.secundary-footer {
	margin: 20px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	border-radius: 15px;
}


/*-------------aditional--------*/
.aditional {
	display: flex;
	justify-content: center;
	margin: 5px;
}

.marca-footer {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	justify-content: center;
	background-color: #0d0d13;
	border-radius: 15px;
	max-height: 300px;
	;
}

.hr-footer-marca {
	opacity: 0.5;
	height: 1px;
	width: 80%;
	background-color: #ffffff;
}

.marca-footer a {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.logo-footer-texto {
	filter: brightness(0) invert(1);
	margin: 15px;
}

.logo-footer-texto-pinlet {
	height: 20px;
}

.logo-footer-texto-MS {
	height: 35px;
}

.logo-footer {
	height: 60px;
	width: 55px;

}

.menu-footer {
	margin-left: 20px;
	display: flex;
	justify-content: space-between;
	padding: 0;
	align-items: flex-start;
	text-align: left;
}

.menu-footer ul {
	display: flex;
	justify-content: space-between;
	align-items: start;
}

.menu-footer li {
	font-size: 15px;
	display: flex;
	flex-direction: column;
	width: 200px;
	margin: 0 45px;
}

.menu-footer li a {
	font-size: 13px;
	padding: 5px;
	color: white;
	text-decoration: none;
	display: flex;
	flex-direction: row;
	align-items: center;
}

.menu-footer ul hr {
	margin: 0 0 10px 0;
	max-width: 200px;
}


.icono-ubicacion {
	height: 15px;
}


.enlaces-footer p {
	color: #ff8c8c;
	border-bottom: #ff8c8c solid 1px;
}

.enlaces-footer a:hover p {
	color: #ffffff;
	border-bottom: #ffffff solid 1px;
}

.enlaces-footer a:hover .icono-ubicacion {
	filter: var(--color-icono-claro);
}

/*-------------tooltips--------*/

.tooltips {
	width: 200px;
}


.redes {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	align-items: center;
}

.iconos-redes {
	filter: brightness(0) invert(1);
	height: 30px;
	margin: 10px;
	transition: all 0.5 ease;
}


.icono-whatsapp {
	transition: all 0.5s;
	height: 80px;
	position: fixed;
	bottom: 40px;
	right: 40px;
	z-index: 1000;
}

.icono-whatsapp:hover {
	height: 90px;
}


.iconos-redes:hover {
	opacity: .5;
}





/*-------------copyright--------*/
.copyright {
	display: flex;
	flex-direction: row;
	font-size: 11px;
	text-align: center;
	color: white;
	width: 100%;
	justify-content: center;
	align-items: center;
	gap: 10px;
}


@media (max-width: 990px) {

	.marca-footer {
		flex-direction: row;
		margin: auto;
		padding: 10px;
	}

	.hr-footer-marca {
		opacity: 0.5;
		height: 100px;
		width: 1px;
		background-color: #ffffff;
	}

	.aditional {
		flex-direction: column;
	}

	.secundary-footer {
		flex-direction: column;
	}

	.tooltips {
		margin: auto;
	}

	.redes {
		flex-direction: row;
	}

	.menu-footer ul li * {
		gap: 10px;
	}
}


@media (max-width: 768px) {

	.menu-footer {
		margin: auto;
	}

	.menu-footer ul {
		margin: auto;
	}

	.secundary-footer {
		width: 90%;
	}

	.copyright {
		display: flex;
		flex-direction: column;
	}

	.icono-whatsapp {
		height: 60px;
		position: fixed;
		bottom: 20px;
		right: 20px;
		z-index: 1000;
	}

}

@media (max-width: 670px) {

	.menu-footer ul {
		flex-direction: column;
		padding: 0;
	}

	.enlaces-footer * {
		justify-content: center;
		text-align: center;
	}
}


@media (max-width: 400px) {

	.marca-footer {
		flex-direction: column;
	}

	.hr-footer-marca {
		opacity: 0.5;
		height: 1px;
		width: 80%;
		background-color: #ffffff;
	}

	.redes {
		flex-direction: column;
	}

	.menu-footer li {
		font-size: 15px;
		display: flex;
		flex-direction: column;
		width: 200px;
		margin: 0;
	}
}