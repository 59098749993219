.main-extra {
    padding: 75px 0 75px 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: center;
    gap: 90px;
    align-items: center;
    background-color: var(--color-fondo-claro);
    color: var(--color-fuente-oscuro);
}


.extra-video video {
    height: auto;
    max-width: 500px;
    border-radius: 30px;
}



.contenedor-info-extra {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 500px;
}

.encabezado-extra {

    max-width: 600px;
    text-align: start;
}

.encabezado-extra h1 {
    font-size: 60px;
    color: var(--color-representativo);
    margin: 0;
}

.encabezado-extra h2 {
    font-size: 25px;
    color: #4b4b4b;
}

.content-centrado {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 75px;
}

.info-extra {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 150px;
    background-color: #0000000c;
    padding: 5px;
    border-radius: 10px;
}

.info-extra img {
    filter: hue-rotate(0deg) saturate(1.5) brightness(1.2);
    width: 50px;
}

.info-extra p {
    color: var(--color-fuente-oscuro);
    font-size: 12px;
}

.info-extra h3 {
    margin: auto;
    width: 150px;
    color: var(--color-fuente-oscuro);
}

@media (max-width: 950px) {

    .main-extra {
        flex-direction: column;
        gap: 25px;
    }

    .content-centrado {
        margin: 25px auto auto auto;
        max-width: 300px;
        gap: 20px;
        transform: translateX(-20px);

    }

    .extra-video video  {
        padding: 0 20px 0 20px;
        height: auto;
        max-width: 500px;
    }

    .encabezado-extra {
        text-align: center;
    }

    .encabezado-extra h1 {
        margin: auto;
        width: 80%;
        font-size: 40px;
        color: var(--color-representativo);
    }

    .encabezado-extra h2 {
        margin: auto;
        width: 70%;
        font-size: 20px;
    }

    .contenedor-info-extra {
        margin: 0 auto 0 auto;
        max-width: 500px;
    }
}

@media (max-width: 500px) {

    .main-extra {
        gap: 20px;
    }

    .extra-video video{
        padding: 0;
        max-width: 95%;
        border-radius: 30px;
    }

    .encabezado-extra {
        text-align: center;
    }

    .encabezado-extra h1 {
        margin: auto;
        width: 80%;
        font-size: 40px;
        color: var(--color-representativo);
    }

    .encabezado-extra h2 {
        margin: auto;
        width: 70%;
        font-size: 20px;
    }

    .contenedor-info-extra {
        margin: 0 auto 0 auto;
        display: flex;
        flex-direction: column;
        max-width: 500px;
    }
}