.main-carousel-Services .slick-slider{
    max-width: 1160px;
    margin: auto;
    padding: 50px 0 50px 0;
}


.main-carousel-Services .slick-slide .img-carousel {
    margin: auto;
    display: block;
    width: 100%;
	border-radius: 15px;
}

.main-carousel-Services .slick-slide .img-carousel-nav {
    margin: auto;
    display: block;
    width: 200px;
    border-radius: 20px;
}


.main-carousel-Services .slick-dots li button:before{
    color: #ac6868 ;
    font-size: 10px;
}


.main-carousel-Services .slick-prev,
.main-carousel-Services .slick-next {
  background-color: #ddd; 
  border-radius: 50%; 
  width: 40px; 
  height: 40px; 
  color: black; 
  z-index: 1;
}



@media (max-width: 990px) {

    .main-carousel-Services .slick-slider{
        width: 95%;
        margin: auto;
    }
	
	.main-carousel-Services .slick-slide{
        border-radius: 15px;
    }
    
    
    .main-carousel-Services .slick-slide img {
        margin: auto;
        display: block;
        max-width: 100%;
        border-radius: 15px;
    }
}