.main-header {
	width: 100%;
	position: fixed;
	opacity: 1;
	left: 0;
	z-index: 1000;
	height: 70px;
	display: flex;
	justify-content: center;
	background-color: var(--color-fondo);
	color: var(--color-fuente);
	box-shadow: 0px 4px 6px #0000001a;
	transition: opacity 0.3s ease;
}

.header-right{
	display: flex;
	flex-direction: row;

}

.main-temas{
	align-content: center;
	margin: 0 15px 0 0 ;
	height: 70px;
}

.main-header.ocultar {
	opacity: 0;
}

.main-header.mostrar {
	opacity: 1;
}

.secundary-header {
	margin: auto;
	padding: 0 0 10px 10px;
	width: 1200px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

/*--------branding--------*/

.branding{
	margin: 0 10px;
	align-content: center;
	height: 70px;
}


.branding a {
	
	display: flex;
	align-items: center;
}

.logo {
	width: 50px;
	height: 50px;
}

.logo img {
	width: 90%;
	height: 100%;
}

.name-marca {
	max-width: 180px;
}

.name-marca img {
	filter: var(--color-icono);
	width: 100%;
}






/*--------------navegacion------------*/


.main-navegation {
	display: flex;
	flex-direction: column;
	justify-content: center;
	background-color: var(--color-fondo);
	

}

#menu-toggle {
	background-color: inherit;
	display: none;
	border: none;
	cursor: pointer;
	max-height: 40px;
	padding: 10px 0;
	width: 100px;
}

.icon-menu {
	width: 30px;
	filter: var(--color-icono);
}

.menu {
	display: flex;
	text-decoration: none;
	list-style-type: none;
	align-items: center;
	padding: 0;
	margin: 0;
}


.menu li a{
	font-size: 16px;
	color: var(--color-fuente);
	transition: color 0.5 ease;
	text-decoration: none;
	display: block;
	text-align: center;
	justify-items: center;
	font-weight: bold;
}

.menu li {
	height: 50px;
	width: 100px;
	transition: background-color 0.5s ease;
	border: 0px solid;
	border-radius: 5px;
	flex: 1;
	padding: 0 15px 0 15px;
	display: flex;
	align-items: center;
	justify-content: center;
}


.menu li img {
	opacity: 0.5;
	height: 40px;
}


.about-section {
	font-size: 16px;
	color: var(--color-fuente);
	cursor: pointer;
	font-weight: bold;
}



@media (max-width: 768px) {

	.main-header.ocultar {
		opacity: 1;
	}
	
	

	.main-navegation {
		width: 123px;
		
	}

	#menu-toggle {
		display: block;
	}

	.menu {
		flex-direction: column;
	}

	.menu li {
		flex-direction: column;
		max-height: 0;
		overflow: hidden;
		padding: 0;
		transition: all 0.3s ease;
	}

	.menu.open li {
		max-height: 40px;
		padding: 15px;
	}

	.menu.open{
		box-shadow: 0px 4px 6px #0000001a;
	}

	nav {
		transition: display 0.5s;
	}

	.name-marca {
		max-width: 125px;
	}

	.logo{
		height: 45px;
		width: 45px;
		justify-content: center;
	}
	
	.name-marca img {
		filter: var(--color-icono);
		width: 100%;
	}

}

@media (max-width: 450px) {

	.name-marca{
		display: none;
	}

}

/* --------------HOVER----------------- */


.menu li:hover {
	background-color: var(--color-representativo);
}

.menu li:hover a {

	color: #fff;
}

.menu .about-section:hover {
	background-color: #aca2a2;
	color: var(--color-fuente-oscuro);
}