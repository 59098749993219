/*-------option--------*/
.option{
    padding: 20px 0;
    display: none;
    width: 100%;
}

.option.visible {
    display: block; 
}

.option.invisible {
    display: none; 
}

/*--------monitoreo------*/

#info-monitoreo{
    display: flex;
    flex-direction: column;
}

.main-info-monitoreo{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    flex-wrap: wrap;
    margin: auto;
}

.main-info-monitoreo div{
    width: 170px;
}

.contenedor-img-monitoreo{
    margin: auto;
}



.main-info-monitoreo .contenedor-img-monitoreo{
    width: 70%;
    background-color: #9e0b0b;
    border-radius: 100px;
}

.main-info-monitoreo img{
    filter: brightness(0) invert(1);
    width:70px;
    margin: 20px;
}

.info-extra-monitoreo{
    max-width: 1000px;
    align-self: center;
}

.info-extra-monitoreo img{
    width: 100%;
}

.monitoreo-vanguardia,
.monitoreo-personalización {
    margin: 20px;
    position: relative;
    max-width: 400px;
    height: 400px;
    overflow: hidden;
    align-items: center;
  }
  
.monitoreo-vanguardia::before,
.monitoreo-personalización::before {
    border-radius: 20px;
    max-width: 400px;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('../../assets/images/nuestras/seguridad-vanguardia.webp');
    background-size: cover;
    background-position: center;
    filter: grayscale(100%);
    opacity: 0.3;
    z-index: 0;
}

.monitoreo-personalización::before{
    background-image: url('../../assets/images/nuestras/personalizado.webp');
}
  
.texto-vanguardia,
.texto-personalizacion {
    padding-top: 70px;
    margin: auto;
    position: relative;
    color: white;
    z-index: 1;
}

.texto-vanguardia h1,
.texto-personalizacion h1 {
    margin: auto;
    
}

.texto-vanguardia p,
.texto-personalizacion p{
    margin: auto;
    width: 50%;
    font-size: 13px;
    line-height: 2;
}



/*--------pinlet-------*/


.pinlet{
    color: white;
    background-color: #3d4751;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 1100px;
    margin: auto;
    padding: 30px;
    border-radius: 30px;
}

.titulo-pinlet{
    padding-bottom: 10px;
    max-height: 100px;
}

.texto-pinlet{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.telefonos{
    max-width: 450px;
}

.telefonos img{
    width: 100%; 
    height: 100%; 
}


.info-pinlet{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 40px 0 0 0;

}

.info-pinlet div{
    display: flex;
    flex-direction: column;
    width: 170px;
    align-items: center;
}

.contenedor-img-pinlet{
    max-width:90px;
    background-color: #ef472b;
    border-radius: 100px;
}

.info-pinlet img{
    filter: brightness(0) invert(1);
    width:45px;
    margin: 20px;
}

.info-pinlet p{
    width: 75%;
}

.descarga-pinlet{
    padding-top: 20px;
    max-width:325px;
    display: flex;
    margin: auto;
    justify-content: center;
}

.descarga-pinlet a{
    width: 100%;
}

.descarga-pinlet div{
    background-color: #1d1e29;
}

.contenedor-img-apple img,
.contenedor-img-google img,
.contenedor-img-huawei img{
    filter: brightness(0) invert(1);
    max-width:45px;
    margin: 20px;
    transition: all .5s ease;
}

.contenedor-img-apple,
.contenedor-img-google,
.contenedor-img-huawei{
    width:100%;
    transition: all .5s ease;
}

.contenedor-img-google{
    border-radius: 20px 0 0 20px;
}


.contenedor-img-huawei{
    border-radius: 0 20px 20px 0;
}

.contenedor-img-apple:hover,
.contenedor-img-google:hover,
.contenedor-img-huawei:hover{
    background-color:white;
}

.contenedor-img-apple:hover img,
.contenedor-img-google:hover img,
.contenedor-img-huawei:hover img{
    filter: brightness(0) invert(0);
}




/*--------hogar-oficina---------*/

.lista-beneficios{
    left: 0;
    z-index: 2;
    margin: 20px;
    justify-content: space-around;
    border-radius: 20px;

}


.lista-beneficios div{
    background-color: #9e0b0b;
    border-radius: 20px;
}

.lista-beneficios div img{
    padding: 25px;
    filter: brightness(0) invert(1);
    max-width: 80px;
    max-height: 80px;
    
}


#hogar,
#oficina{
    max-width: 900px ;
    margin: auto;
}

.contenido-hogar,
.contenido-oficina{
    position: relative;
    height: 500px;

}

.contenedor-img-hogar,
.contenedor-img-oficina{
    right: 50px;
    z-index: 1;
    border-radius: 20px;
    max-width: 700px;
    max-height: 600px;
}

.contenedor-img-hogar img,
.contenedor-img-oficina img{
    border-radius: 20px;
    width: 750px;
    height: 490px;
    opacity: 1;
}

.info-hogar,
.info-oficina{
    max-width: 450px;
    margin: 15px;
    display: flex;
    flex-direction: row;
}

.lista-beneficios,
.contenedor-img-oficina,
.contenedor-img-hogar{
    position: absolute;
}

.info-hogar h3,
.info-hogar p,
.info-oficina h3,
.info-oficina p{
    text-align: start;
    padding: 0 ;
    margin: 0 10px 0 0;
    color: white;
}

.info-hogar ,
.info-oficina {
    align-items: center;
}

/*----------hogar-------*/




/*--------oficina-----------*/

@media (max-width: 850px){

    .pinlet{
        flex-direction: column;
    }
    
    .contenedor-img-hogar,
    .contenedor-img-oficina{
        display: none;
    }

    .lista-beneficios{
        left: 50%;
        transform: translate(-50%, 0);
    }
}


@media (max-width: 700px) {
    #pinlet{
        flex-direction: column;
    }

    .contenedor-img-apple,
    .contenedor-img-google{
        max-width:200px;
    }

    .lista-beneficios{
        margin: auto;
    }

    .contenido-hogar,
    .contenido-oficina{
        flex-direction: column;
    }
    

}




