.main-menu-servicio{
    height: 150px;
    max-width: 1000px;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin: auto;
}


.boton{
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70px;
    width: 70px;
    flex: 0 1 auto;
    background-color: inherit;
    border: none;
    transition: all 0.20s;
    cursor: pointer;
    border-radius: 80px;
}


.boton img{
  width: 100%; 
  height: auto; 
}

.svg img{
	filter: var(--color-icono);

}



/*----------hover---------*/

.boton:hover{
    padding: 20px;
    height: 110px;
    width: 110px;
    background-color: #9e0b0b;
    border-radius: 80px;
}

.svg:hover img{
    filter: brightness(0) invert(1);
}

.png:hover{
    background-color: #b8b8b8;
}

@media (max-width: 750px) {

	.boton{
        height: 60px;
        width: 60px;
    }
}

