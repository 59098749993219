
.main-about{
    max-width: 1200px;
    margin: 50px auto;

}

.info-about{
    position: relative;
    z-index: 1;
    background-color: #0d0d17;
    padding: 70px;
    border-radius: 30px;
}

.info-about::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-image: url('../assets/images/fondos/fondo-about.webp');
    opacity: 0.4;
    filter: grayscale(1);
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
    border-radius: 30px;
}

.content-info-about {
    display: flex;
    flex-direction: row;
    margin: auto;
    align-self: center;
    background-color: #00000081;
    backdrop-filter: blur(10px);
    align-items: center;
    padding: 20px;
    justify-content: space-between;
    border-radius: 25px;
    gap: 20px;
}

.texto-info-about{
    max-width: 450px;
    color: var(--color-fuente-claro);
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 20px;
}

.texto-info-about h1,
.texto-info-about p{
    width: 100%;
    padding: 0;
    margin: 0;
}

.texto-info-about h1{
    font-size: 55px;
}

.img-info-about{
    max-width: 375px;
}

.img-info-about img{
    width: 100%;
    border-radius: 20px;
    
}

/*--------mision vision--------*/

.vision-mision {
    padding: 100px 0 0 0 ;
    width: 1100px;
    display: flex;
    flex-direction: row;
    margin: auto;
    justify-content: space-around;
}

.vision-mision div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.mision img,
.vision img {
    height: 150px;
    width: 150px;
    margin: auto;
}

.mision h2,
.vision h2 {
    font-size: 40px;
}

.mision p,
.vision p {
    max-width: 350px;
    margin: auto;
}


@media (max-width: 1100px) {

    .vision-mision{
        width: 800px;
    }

    .texto-info-about h1{
        font-size: 50px;
    }
}

@media (max-width: 950px) {
    .texto-info-about h1{
        font-size: 45px;
    }
}

@media (max-width: 900px) {
    .vision-mision{
        width: 750px;
    }
    .content-info-about {
        flex-direction: column;
        gap: 30px;
        align-items: start;
    }

    .img-info-about{
        max-width: 800px;
    }

}


@media (max-width: 750px) {

    .vision-mision{
        width: 325px;
    }

    .vision-mision {
        flex-direction: column;
        gap: 50px;
    }

    .mision p,
    .vision p {
        width: 325px;
        margin: auto;
    }

    .info-about{
        padding: 40px;
    }
}

@media (max-width: 480px) {

    .texto-info-about h1{
        font-size: 40px;
    }
}