.main-deslizador {
  max-width: 1200px;
  margin: auto;
}

.fondo-deslizador {
  position: relative;
  width: 100%;
}

.img-deslizador {
  width: 100%;
  height: 450px;
  background-color: black;
  margin: 50px 0;
}

.img-deslizador img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  opacity: 0.5;
}

.contenido-deslizador {
  position: absolute;
  z-index: 1000;
  max-width: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  align-content: center;
}

.info-deslizador {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 100px;
}

.info-deslizador h1 {
  color: var(--color-fuente-claro);
  font-size: 80px;
  max-width: 400px;
}

.icon-deslizador {
  opacity: 0.7;
  width: 250px;
  padding: 3%;
  border: 10px solid #ffffff;
  border-radius: 20px;
  align-content: center;
}

.icon-deslizador img {
  width: 100%;
  filter: var(--color-icono-claro);
}


/*----modificacion de arrows--------*/

.main-deslizador .slick-prev::before,
.slick-next::before {
  transition: all 0.5s ease;
  background-color: #ffffff00;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  bottom: 0;
  content: "";
  height: 6rem;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  transform: scaleX(1);
  width: 2em;
}

/* Posicionamiento de las flechas */
.main-deslizador .slick-prev {
  background-image: url('../../assets/images/icon/flecha-izquierda.png');
  left: 0px;
  z-index: 1;
}


.main-deslizador .slick-prev::before {
  background-image: url('../../assets/images/icon/flecha-izquierda.png');
  border-radius: 0 5px 5px 0;
}

.main-deslizador .slick-next {
  right: 20px;
  /* Ajusta para mover la flecha derecha */
  z-index: 1;
}

.main-deslizador .slick-next::before {
  background-image: url('../../assets/images/icon/flecha-derecha.png');
  border-radius: 5px 0 0 5px;
}

.main-deslizador:hover .slick-prev::before,
.main-deslizador:hover .slick-next::before {
  background-color: #ffffff6e;

}

/*----------modificación de dots----------*/

.main-deslizador .slick-dots button {
  top: -100px;
  position: absolute;
}

.main-deslizador .slick-dots li {
  padding: 0 10px 0 10px;
}


.main-deslizador .slick-dots .slick-active button::before {
  color: #ffffff;
  opacity: 1;

}

.main-deslizador .slick-dots button::before {
  font-family: 'slick';
  font-size: 20px;
  content: '•';
  text-align: center;
  opacity: .5;
  color: #ffffff;
}

@media(max-width: 890px) {
  .info-deslizador {
    gap: 50px;
  }

  .main-deslizador .slick-dots button {
    top: -90px;
    position: absolute;
  }

  .main-deslizador .slick-dots button::before {
    font-size: 18px;
  }

  .img-deslizador {
    height: 400px;
  }

  .info-deslizador h1 {
    color: var(--color-fuente-claro);
    font-size: 60px;
  }

}


@media(max-width: 750px) {

  .img-deslizador {
    height: 275px;
  }

  .main-deslizador .slick-dots button {
    top: -85px;
    position: absolute;
  }

  .main-deslizador .slick-dots li {
    padding: 0 5px 0 5px;
  }

  .main-deslizador .slick-dots button::before {
    font-size: 15px;
  }

  .icon-deslizador {
    max-width: 150px;
    border: 10px;
  }

  .info-deslizador h1 {
    font-size: 50px;
  }

  .main-deslizador .slick-prev::before,
  .slick-next::before {
    height: 4rem;
    width: 1.5em;
  }

  .info-deslizador {
    gap: 25px;
  }

  .main-deslizador .slick-next {
    right: 10px;
    z-index: 1;
  }
}

@media(max-width: 550px) {

  .img-deslizador {
    height: 200px;
  }

  .main-deslizador .slick-dots li {
    padding: 0 0 0 0;
  }

  .main-deslizador .slick-dots button::before {
    font-size: 12px;
  }


  .icon-deslizador {
    max-width: 100px;
  }

  .info-deslizador h1 {
    font-size: 35px;
  }

  .info-deslizador {
    gap: 10px;
  }
}