

.main-sections{
    width: 100%;
}

/*----------section----*/
/*
.section{
    padding: 80px 0;
}

.section img{
    width: 500px;
    border-radius: 20px;
}
*/

.section{
    padding: 20px 0;
}

.section img{
    width: 300px;
    border-radius: 20px;
}


/*---------card-section-------*/
.card-section{
    display: flex;
    max-width: 850px;
    margin: auto;;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    gap: 30px;
}

.section-color1{
    background-color: #00000048;
}

.section-color2{
    background-color: #00000010;
}



.card-section .text{
    width: 900px;
    padding: 0 0 0 10px;
    margin: 40px 0 40px 0 ;
}

.text h1{
    
    font-size: 30px;
}

.text p{
    font-size: 14px;
}

.text h1,
.text p{
    margin: 0;
}

@media (max-width: 768px) {

    .section{
        padding: 40px 0;
    }
    
    
    .section img {
        width: 80%; 
    }

    .card-section{
        flex-direction: column;
    }

    .card-section .text {
        width: 70%;
        text-align: left;
    }
}