.main-cards {
    max-width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 30px;
    color: var(--color-fuente);

}

.card {
    background: #Ffffff;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: row;
    gap: 30px;
    border-radius: 20px;
    padding: 20px;

}

.content-card {
    margin: auto;
}

.card h2 {
    margin: 0;
}

.card p {
    width: 100%;

    margin: 5px;
}

.card a {
    width: 100%;
    text-decoration: none;
    color: var(--color-fuente);
    display: flex;
    flex-direction: row;
    gap: 30px;
}



.img-card {
    width: 90px;
    height: 90px;
    border: solid 2px var(--color-representativo);
    align-content: center;
    border-radius: 100px;
}

.img-card img {
    filter: var(--color-icono);
    width: 50%;
}

/*-----------hover-----*/

.card:hover {
    background: #8b8b8b28;
}

@media(max-width: 950px) {

    .main-cards {
        gap: 30px;
        margin: auto;
    }

    .card {
        justify-content: center;
    }

}



@media(max-width:600px) {

    .main-cards {
        gap: 20px;
        padding: 15px;
    }

    .card {
        align-items: center;
        gap: 15px;
        padding: 10px;

    }

    .card a {
        align-items: center;
        gap: 15px;
        padding: 0px;
    }

    .img-card {
        width: 70px;
        height: 70px;
    }
    
    .content-card{
        align-items: start !important;
        width: 70%;
    }

}